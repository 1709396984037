import React from 'react'
import Layout from "../components/theme/layout"
import { Link } from "gatsby"
import SEO from "../components/theme/seo"
// import Instagram from "../components/instagram"

import { Container, Row, Col, Tab, ListGroup, Breadcrumb } from 'react-bootstrap'

export default function Wax() {
    return (
        <Layout>
        <SEO title="Detailing Packages" />
            <div className="masthead white coatings">
                <Container>
                    <Row><h1>Coatings &amp; Waxes</h1>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
                        <Breadcrumb.Item active>Coating &amp; Wax</Breadcrumb.Item>
                    </Breadcrumb>
                    </Row>
                </Container>
            </div>
            <Container fluid>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#m26">
            <Row className="mobile-reverse">
              <Col sm={4} className="section-toggle">
                <ListGroup>
                  <ListGroup.Item action href="#m26">
                      M26 High Tech Carnauba Paste Wax
                  </ListGroup.Item>
                  <ListGroup.Item action href="#m2116">
                      M2116 Synthetic Sealant 2.0
                  </ListGroup.Item>
                  <ListGroup.Item action href="#gyeon">
                      Gyeon Q2M Wet Coat - Silica Si02 Ceramic Spray Top Coat
                  </ListGroup.Item>
                  <ListGroup.Item action href="#ceramic">
                    Ceramic Coating Package
                  </ListGroup.Item>
                  <ListGroup.Item action href="#what-ceramic">
                    What is a Ceramic Coating?
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col sm={8} className="package-contents">
                <Tab.Content>
                  <Tab.Pane eventKey="#m26">
                    <h2>M26 High tech carnauba paste wax</h2>
                    <h3 className="gold"><small>6 months of protection</small></h3>
                    <p>This is a super durable, high quality carnauba wax which we apply with a soft pad and an orbital polisher. It is blended with silicones, polymers, and it provides a super high gloss finish. It adds richness and depth to all colors and is highly rated throughout the auto community and also by our valued customers! You won’t be disappointed with this stuff!</p>
                    <ul>
                        <li>Soft Pad</li>
                        <li>Carnauba Paste Wax</li>
                        <li>Orbital Polisher</li>
                    </ul>
                    <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$35</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-Size Vehicles</h3>
                            <h4>From<br/>$40</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$45</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$50</h4>
                        </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#m2116">
                    <h2>M2116 Synthetic Sealant 2.0</h2>
                    <h3 className="gold"><small>8 months of protection</small></h3>
                    <p>Welcome to the new age of protective coatings! Formulated with Hydrophobic Polymer Technology™, this super hydrophobic paint sealant provides outstanding protection, water beading effects, and adds a super high-gloss finish. It bonds to create an extremely durable and flexible shield that protects from acid rain, industrial pollution/fallout, road grime, UV rays, water spots, and fading/ oxidation. Unlike other sealants, it visually eliminates fine scratches and swirls creating a flawless finish that is noticeably deeper, darker, more reflective, and is super smooth to the touch. We also apply this paste with an orbital polisher and a soft pad. This sealant lasts longer than waxes, and will also better protect your vehicles paint. You will see why this stuff speaks for itself, and once you try it, you might not want any other waxes or sealants to touch your vehicle!</p>
                    <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$50</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-Size Vehicles</h3>
                            <h4>From<br/>$55</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$60</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$65</h4>
                        </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#gyeon">
                    <h2>Gyeon Q2M Wet Coat - Silica Si02 Ceramic Spray Top Coat</h2>
                    <h3 className="gold"><small>3 months of protection</small></h3>
                    <p>You've heard of waxes, sealants, maybe even ceramic coatings, but have you heard of ceramic sprays? They are more affordable than professional ceramic coatings and you can reap the benefits of those incredible beading Si02 properties! Q2M Wet Coat bonds in contact with water, so we apply this product after our thorough hand wash while the vehicle is still wet. We apply this to your entire vehicle including windows, mirrors, and rims which will help prevent brake dust build up and keep them looking fresh longer! You may add this as a top coat to other waxes or sealants as well for that ultimate protection and longevity. You will truly be amazed by how well this product works and is highly recommended!</p>
                    <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$65</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-size Vehicles</h3>
                            <h4>From<br/>$70</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$75</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$80</h4>
                        </div>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="#ceramic">
                    <h2>Ceramic Coating Package</h2>
                    <h3 className="gold"><small>2-3 years  of protection</small></h3>
                    <p>For our full ceramic coating package your vehicle will receive a thorough rinse followed by a foam bath and a thorough 2 bucket hand wash which will remove any bugs, bird droppings and tar. It will be clay bar treated and the paint will be decontaminated using fallout remover. The tires are scrubbed clean and then dressed, the rims are deep cleaned using soft brushes, and the wheel wells are cleaned.  We will then assess the paint and begin the polishing process (1-3 Step) bringing back supreme depth and clarity. The surface is then prepped and System X ceramic coating is applied to the paints surface. The windows are cleaned and any chrome exhaust tips are polished and protected, and all plastic trim is dressed.</p>
                    <ul>
                        <li>Full exterior detail</li>
                        <li>Full Polish</li>
                        <li>Ceramic Coating Applied</li>
                        <li>Paint Prep</li>
                    </ul>
                    <Row>
                        <div className="pricing-block">
                            <h3>Bikes</h3>
                            <h4>From<br/>$450</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$575</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-size Vehicles</h3>
                            <h4>From<br/>$750</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$950</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4><Link to="/contact">Contact</Link></h4>
                        </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#what-ceramic">
                    <h2>What is a Ceramic Coating?</h2>
                    <h3 className="gold"><small>We have all been hearing about ceramic coatings, but what is it exactly?</small></h3>
                    <p>Well it is a revolutionary, super glossy hydrophobic nano coating which was first designed for oil and space industries and it has now made it's way into the automotive industry. It is a clear, flexible, and durable coating that acts as sacrificial layer of protection that can be applied to various types of surfaces and will protect those surfaces for years!</p>
                    <p>Those surfaces include, paint, metals, plastics, windows, fabrics, suede/ nubuck, and leather.</p>
                    <p>It will protect your vehicle from marring, etching caused by water, bugs or bird droppings, harmful Uv rays and oxidation. It also helps prevent dust, brake dust, dirt, tar, and sap from sticking.</p>
                    <p>It has a super high gloss finish and is rated at a 9h thickness (100xs thicker than your typical wax). It makes your vehicle super easy to wash, it stays cleaner longer, you never have to wax  your car, and it bonds strongly to surfaces so one coat can last up to 2 years of being exposed to elements!</p>
                </Tab.Pane>                  
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          </Container>
        </Layout>
    )
}


